import React from "react";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { FaPhoneVolume } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-light_bg_primary dark:bg-dark_bg_primary text-md py-10 px-6 lg:px-12 w-full animation">
      <div
        className="mx-auto pb-6 flex flex-col lg:justify-between lg:flex-row"
        style={{ borderBottom: "2px solid gray" }}
      >
        <div className="flex flex-col space-y-4 mb-4 lg:mb-0 lg:w-[25%]">
          <div>
            <img
              src="/images/netsflow.webp"
              alt="NetsFlow"
              className="h-[5rem]"
            />
          </div>
          <p className="text-[#101010] dark:text-gray-300">
            Netsflow is your go-to partner for digital excellence. We deliver
            customized web and Flutter development solutions tailored to your
            business needs
          </p>
          <div className="flex flex-row space-x-4 text-[#101010] dark:text-gray-300">

            <Link
              to="https://www.linkedin.com/company/netsflow/"
              target="_blank"
            >
              <FaLinkedin
                className="cursor-pointer hover:text-linkedin transition-all duration-300"
                style={{ fontSize: "1.3rem" }}
              />
            </Link>

            <Link
              to="https://www.instagram.com/netsflowofficial/"
              target="_blank"
            >
              <FaInstagram
                className="cursor-pointer hover:text-instagram transition-all duration-300"
                style={{ fontSize: "1.3rem" }}
              />
            </Link>

            <Link to="https://www.youtube.com/@netsflow" target="_blank">
              <FaYoutube
                className="cursor-pointer hover:text-youtube transition-all duration-300"
                style={{ fontSize: "1.3rem" }}
              />
            </Link>
          </div>
        </div>

        <div className="flex flex-col space-y-4 mb-4">
          <h5 className="font-bold text-lg text-sec_col">OFFICE INFO</h5>
          <div className="flex flex-wrap items-center lg:flex-nowrap lg:flex-col lg:items-start">
            <p className="text-[#101010] dark:text-gray-300 mb-2 mr-4 lg:mr-0">
              BIC, University of Gujrat, Gujrat
              <br />
            </p>

            <div className="flex flex-wrap lg:block lg:flex-nowrap">
              <div className="flex mr-4 items-center mb-4 lg:mr-0">
                <IoIosMail
                  className="text-sec_col"
                  style={{ fontSize: "1.5rem" }}
                />
                <Link
                  to="mailto:info@netsflow.com"
                  className="text-sm ml-2 text-[#101010] dark:text-gray-300 cursor-pointer hover:text-nav_hover sm:hover:drop-shadow-icon_custom transition-all duration-300"
                >
                  info@netsflow.com
                </Link>
              </div>

              <div className="flex items-center mr-4 mb-4 lg:mr-0">
                <FaPhoneVolume
                  className="text-sec_col"
                  style={{ fontSize: "1.3rem" }}
                />
                <Link
                  to="tel:0300 6232929"
                  className="text-sm ml-2 text-[#101010] dark:text-gray-300 cursor-pointer hover:text-nav_hover sm:hover:drop-shadow-icon_custom transition-all duration-300"
                >
                  +92 300 6232929
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col space-y-4 mb-4 lg:mb-0">
          <h5 className="font-bold text-lg text-sec_col">SERVICES</h5>

          <div className="flex flex-wrap lg:flex-col lg:flex-nowrap">
            <Link to="/services">
              <p className="mr-4 mb-2 text-[#101010] dark:text-gray-300 cursor-pointer hover:text-nav_hover sm:hover:drop-shadow-icon_custom transition-all duration-300 lg:mr-0">
                Web Development
              </p>
            </Link>

            <Link to="/services">
              <p className="mr-4 mb-2 text-[#101010] dark:text-gray-300 cursor-pointer hover:text-nav_hover sm:hover:drop-shadow-icon_custom transition-all duration-300 lg:mr-0">
                App Development
              </p>
            </Link>

            <Link to="/services">
              <p className="mr-4 mb-2 text-[#101010] dark:text-gray-300 cursor-pointer hover:text-nav_hover sm:hover:drop-shadow-icon_custom transition-all duration-300 lg:mr-0">
                Socia Media Marketing
              </p>
            </Link>

            <Link to="/services">
              <p className="mb-2 text-[#101010] dark:text-gray-300 cursor-pointer hover:text-nav_hover sm:hover:drop-shadow-icon_custom transition-all duration-300 lg:mr-0">
                SEO
              </p>
            </Link>
          </div>
        </div>

        <div className="flex flex-col space-y-4 ">
          <h5 className="font-bold text-lg text-sec_col">QUICK LINKS</h5>
          <ul className="flex flex-row flex-wrap lg:flex-col lg:flex-nowrap lg:list-disc lg:list-inside">
            <Link to="/ourwork">
              <li className="mr-4 text-[#101010] dark:text-gray-300 cursor-pointer mb-2 hover:text-nav_hover sm:hover:drop-shadow-icon_custom transition-all duration-300 lg:mr-0">
                Our Work
              </li>
            </Link>

            <Link to="/about">
              <li className="mr-4 text-[#101010] dark:text-gray-300 cursor-pointer mb-2 hover:text-nav_hover sm:hover:drop-shadow-icon_custom transition-all duration-300 lg:mr-0">
                About Us
              </li>
            </Link>

            <Link to="/">
              <li className="mr-4 text-[#101010] dark:text-gray-300 cursor-pointer mb-2 hover:text-nav_hover sm:hover:drop-shadow-icon_custom transition-all duration-300 lg:mr-0">
                Privacy Policy
              </li>
            </Link>

            <Link to="/">
              <li className="mr-4 text-[#101010] dark:text-gray-300 cursor-pointer mb-2 hover:text-nav_hover sm:hover:drop-shadow-icon_custom transition-all duration-300 lg:mr-0">
                Our Terms
              </li>
            </Link>
          </ul>
        </div>
      </div>

      <div className="mx-auto mt-4 flex items-center justify-center flex-col">
        <p className="text-[#101010] dark:text-gray-300 mb-2">
          All Rights Reserved &copy;&nbsp;
          <Link to="/">
            <span className="text-nav_hover cursor-pointer">Netsflow</span>
          </Link>
          &nbsp;2024
        </p>
      </div>
    </footer>
  );
};

export default Footer;
