import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { ThemeProvider } from "./context/ThemeContext";
const clientId = process.env.REACT_APP_GOOGLE_CLIENTID;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider>
  <Provider store={store}>
  <GoogleOAuthProvider clientId={clientId}>
    <Router>
      <App />
    </Router>
    </GoogleOAuthProvider>
  </Provider>
  </ThemeProvider>
);
