import { RiTeamLine } from "react-icons/ri";
import { MdMiscellaneousServices } from "react-icons/md";
import { AiOutlineProduct } from "react-icons/ai";
import { MdContactSupport } from "react-icons/md";
import { IoMdContacts } from "react-icons/io";
import { BsPcDisplayHorizontal } from "react-icons/bs";
import { TbDeviceDesktopCode } from "react-icons/tb";
import { TbDeviceMobileCode } from "react-icons/tb";
import { TbWorldSearch } from "react-icons/tb";

export const Data = [
  {
    id: 1,
    title: "SERVICES",
    description: "Embrace the change and the change will transform you",
    icon: MdMiscellaneousServices,
    link: "/services",
  },
  {
    id: 2,
    title: "TEAM",
    description: "Our exceptional team will make clear our achievements",
    icon: RiTeamLine,
    link: "/team",
  },
  {
    id: 3,
    title: "PRODUCTS",
    description: "Discover our products, and our quality will speak for itself",
    icon: AiOutlineProduct,
    link: "/ourwork",
  },
  {
    id: 4,
    title: "ABOUT US",
    description: "Discover who we are and how our values guide our mission",
    icon: MdContactSupport,
    link: "/about",
  },
  {
    id: 5,
    title: "CONTACT",
    description:
      "Reach out to us and experience the support that sets you free",
    icon: IoMdContacts,
    link: "/contact",
  },
];

export const ServicesData = [
  {
    id: 1,
    title: "UI/UX DESIGNING",
    descriptionUnique: "Websites that wow!",
    description:
      " Design a stunning, user-friendly website that informs and converts visitors into customers.",
    icon: BsPcDisplayHorizontal,
  },
  {
    id: 2,
    title: "WEB DEVELOPMENT",
    descriptionUnique: "Build robust, scalable websites!",
    description:
      " Develop dynamic, high-performance websites that engage users and drive growth.",
    icon: TbDeviceDesktopCode,
  },
  {
    id: 3,
    title: "MOBILE APPS",
    descriptionUnique: "Apps that rock!",
    description:
      " Create mobile experiences that engage users and boost your on-the-go sales.",
    icon: TbDeviceMobileCode,
  },
  // {
  //   id: 4,
  //   title: 'DIGITAL MARKETING',
  //   descriptionUnique: 'Unleash the power of online!',
  //   description: ' Reach new fans and skyrocket your brand with targeted campaigns.',
  //   icon: LuWebhook,
  // },
  {
    id: 4,
    title: "WORDPRESS DEVELOPMENT",
    descriptionUnique: "Get website, in no times! ",
    description: " Make your websites in no time at minimum cost.",
    icon: TbWorldSearch,
  },
  // {
  //   id: 6,
  //   title: 'BRAND DESIGNING',
  //   descriptionUnique: 'Make your brand unforgettable!',
  //   description: ' Design a visual identity that speaks directly to your ideal customers.',
  //   icon: FaRegPaperPlane,
  // },
  // {
  //   id: 7,
  //   title: 'GOOGLE ADS',
  //   descriptionUnique: 'Skyrocket your reach!',
  //   description: ' Boost your traffic and increase your business visibility and ROI.',
  //   icon: MdOutlineRocketLaunch,
  // },
  // {
  //   id: 8,
  //   title: 'VIDEO CREATION',
  //   descriptionUnique: 'Ditch the boring!',
  //   description: ' Tell your brand story with engaging videos that capture hearts and minds.',
  //   icon: MdVideoSettings,
  // }
];

export const OurWorkData = [
  {
    id: 1,
    title: "COLLEGE MASTER MIND",
    description: "",
    img: "images/college-master-mind.webp",
    link: "https://collegemastermind.com/",
  },
  {
    id: 2,
    title: "CODER BS",
    description: "",
    img: "images/coder-bs.webp",
    link: "https://coderbs.netlify.app/",
  },
  {
    id: 3,
    title: "FAST STREAMING",
    description: "",
    img: "images/fast-streaming.webp",
    link: "https://fast-streaming.netlify.app/",
  },
  // {
  //   id: 4,
  //   title: 'HAJVERY GROUP',
  //   description: '',
  //   img: 'images/service.webp',
  // },
  // {
  //   id: 5,
  //   title: 'HAJVERY GROUP',
  //   description: '',
  //   img: 'images/service.webp',
  // },
];

export const teamData = [
  {
    id: 1,
    name: "MUHAMMAD UMAIR",
    role: "FOUNDER",
    introUnique: "Full-Stack Mobile Developer",
    intro:
      " with expertise in Flutter and backend services, I build scalable and robust mobile applications. My focus is on ensuring high quality, optimized performance, and strong security, while successfully turning client ideas into fully realized products.",
    img: "/images/MuhammadUmair.webp",
  },
  {
    id: 2,
    name: "MUHAMMAD FAIZAN",
    role: "CO-FOUNDER",
    introUnique: "Full-Stack Web Developer",
    intro:
      " Leveraging the MERN stack and backend skills, I design and build high-performing web applications. My dedication is to ensuring robust functionality, high security, and excellent user experience while turning your vision into effective solution.",
    img: "/images/MuhammadFaizan.webp",
  },
  {
    id: 3,
    name: "MUBASHAR MEHMOOD",
    role: "CMO",
    introUnique: "Business Growth Strategist",
    intro:
      " with expertise in critical thinking, strategic planning, and brand mobilization, I drive business growth and close key deals. My focus is on crafting winning proposals, mastering negotiation, boost growth and delivering impactful content.",
    img: "/images/MubasharMehmood.webp",
  },
];

export const blogsData = [
  {
    id: 1,
    title: "Transforming Business Communication with Netsflow",
    slug: "transforming-business-communication-with-netsflow",
    author: "Muhammad Faizan | Co-Founder - Netsflow",
    publishedAt: "January 18, 2025",
    desc: "Discover how Netsflow revolutionizes the way businesses connect and collaborate with its innovative solutions.",
    content: `

### Why Communication Matters

Efficient communication is essential for aligning teams, engaging clients, and fostering long-term growth. Traditional methods often fall short in addressing the dynamic needs of modern enterprises. That's where Netsflow steps in.

### Netsflow's Unique Offerings

- **Unified Platform**: Streamline conversations, emails, and file-sharing on one intuitive platform.
- **Custom Integrations**: Adapt the platform to meet your business's unique needs.
- **Robust Security**: Safeguard your sensitive information with state-of-the-art encryption.

### Real-World Impact

Companies using Netsflow have reported a 30% increase in productivity and a significant improvement in internal and external communication. Whether it's coordinating remote teams or managing customer interactions, Netsflow ensures seamless connectivity.

### Future Prospects

Netsflow is constantly evolving, incorporating AI and advanced analytics to redefine business communication further.

Make the switch to Netsflow and witness a transformation in how your business communicates.

    `,
  },
  {
    id: 2,
    title: "5 Reasons to Choose Netsflow for Your Business",
    slug: "5-reasons-to-choose-netsflow-for-your-business",
    author: "Muhammad Faizan | Co-Founder - Netsflow",
    publishedAt: "January 18, 2025",
    desc: "Explore the top reasons why Netsflow is the ultimate solution for modern businesses.",
    content: `

1. **User-Friendly Interface**: Netsflow's sleek and intuitive design ensures that even non-technical users can navigate effortlessly.

2. **Advanced Collaboration Tools**: From project management to real-time chats, Netsflow brings everything you need under one roof.

3. **Exceptional Customer Support**: Our dedicated support team is available 24/7 to resolve any issues and provide guidance.

4. **Cost-Effectiveness**: With flexible pricing plans, Netsflow caters to businesses of all sizes without compromising on quality.

5. **Scalability**: Whether you're a startup or an enterprise, Netsflow grows with your business, adapting to your increasing needs.

In a world where efficiency is key, Netsflow stands out as the smart choice. Embrace Netsflow and unlock unparalleled productivity and connectivity.
    `,
  },
  {
    id: 3,
    title: "How Netsflow Supports Remote Workforces",
    slug: "how-netsflow-supports-remote-workforces",
    author: "Muhammad Faizan | Co-Founder - Netsflow",
    publishedAt: "January 18, 2025",
    desc: "Learn how Netsflow empowers remote teams to collaborate effectively and stay productive.",
    content: `

### Bridging the Gap

Remote work can often lead to miscommunication and reduced productivity. Netsflow's platform bridges the gap, ensuring everyone stays on the same page.

### Key Features for Remote Work

- **Video Conferencing**: Conduct seamless virtual meetings with HD video and audio.
- **Task Management**: Assign, track, and complete tasks efficiently, no matter where your team is located.
- **Cloud Storage**: Access essential files from anywhere with secure cloud storage.
- **Time-Zone Coordination**: Collaborate with global teams using built-in time-zone management tools.

### Success Stories

Many companies have transitioned to remote work with the help of Netsflow, reporting increased employee satisfaction and better work-life balance.

### The Future of Remote Work

As remote work becomes the norm, Netsflow continues to innovate, introducing AI-driven features like smart scheduling and advanced analytics.

Empower your remote workforce with Netsflow and ensure your team stays connected, productive, and motivated.
    `,
  },
];
