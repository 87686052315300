import React, { useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import { blogsData } from '../data';
import Navbar from '../components/Navbar';
import ReactMarkdown from "react-markdown";
import Footer from '../components/Footer';

const BlogContent = () => {
    useEffect(() => {
            window.scrollTo(0, 0);
          }, []);

    const { slug } = useParams(); // Get the blog ID from the URL
    const blog = blogsData.find((blog) => blog.slug === slug);
  
    if (!blog) {
        return <p className="text-center text-red-500">Blog not found.</p>;
      }
    
      return (
        <>
        <section>
        <Navbar />
        {/* <section className="min-h-[55vh] lg:min-h-[80vh] relative flex flex-col items-center justify-center">
          <div
            className="min-h-[55vh] lg:min-h-[80vh] absolute top-0 w-full left-0 z-20"
            style={{
              backgroundColor: "#181818",
              opacity: "0.8",
            }}
          ></div>

          <div
            className="min-h-[55vh] lg:min-h-[80vh] absolute top-0 w-full left-0 bg-cover bg-center z-10"
            style={{
              backgroundImage: "url('images/team.webp')",
            }}
          ></div>

          <div className="min-h-[55vh] lg:min-h-[80vh] absolute top-0 left-0 z-30 w-full flex flex-col items-center justify-center text-center px-6 lg:px-0">
            <h1 className="mb-2 text-[2rem] sm:mb-4 sm:text-[3rem]">TEAM</h1>
            <p className="text-gray-300 text-md lg:w-[70%] lg:text-xl">
              Meet the dedicated minds behind Netsflow! Our expert team combines
              creativity with technical skill to bring your digital vision to
              life. Together, we strive for innovation, collaboration, and
              success in every project we undertake.
            </p>
          </div>
        </section> */}
      </section>
      
        <section className="mt-20 max-w-4xl mx-auto p-6 bg-light_bg_secondary dark:bg-dark_bg_secondary rounded-md">
          {/* Blog Header */}
          <header className="mb-6 border-b border-[#101010] dark:border-gray-400 pb-4">
            <h1 className="text-3xl font-bold text-[#101010] dark:text-gray-300">{blog.title}</h1>
                <span className="text-sm text-[#101010] dark:text-gray-300 mt-4 font-semibold block">By {blog.author}</span>
              <span className="text-sm text-[#101010] dark:text-gray-300 mt-2 font-semibold">{blog.publishedAt}</span>
          </header>
    
          {/* Blog Content */}
          {/* <main className="space-y-6 text-gray-300">
            {blog.content.split("\n\n").map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </main> */}

          <ReactMarkdown className="space-y-6 text-[#101010] dark:text-gray-300">{blog.content}</ReactMarkdown>
    
          <div className="flex justify-between items-center mt-8 pt-4 border-t border-gray-400">
          <div className='text-left'>
            <Link
              to={`/blog/${blogsData[blog.id - 2]?.slug}`}
              className={`text-[#101010] dark:text-gray-300 hover:text-sec_col dark:hover:text-sec_col font-bold transition-all duration-300 ${
                blog.id === 1 && "hidden"
              }`}
            >
              <span className='text-sec_col'>⬅ Previous</span>
              <p className='font-normal'>{blogsData[blog.id - 2]?.title}</p>
            </Link>
          </div>

            <div className='text-right'>
            <Link
              to={`/blog/${blogsData[blog.id]?.slug}`}
              className={`text-[#101010] dark:text-gray-300 hover:text-sec_col dark:hover:text-sec_col font-bold transition-all duration-300 ${
                blog.id === blogsData.length && "hidden"
              }`}
            >
              <span className='text-sec_col'>Next ➡</span>
              <p className='font-normal'>{blogsData[blog.id]?.title}</p>
            </Link>
            </div>
          </div>
        </section>

        <section className="py-8">
        <div className='p-6 rounded-md shadow-md max-w-4xl mx-auto border-2 border-[#101010] dark:border-gray-300 bg-light_bg_secondary dark:bg-dark_bg_secondary'>
        <h2 className="text-lg font-semibold text-[#101010] dark:text-gray-300 mb-2">Leave a Comment</h2>
      <p className="text-sm text-[#101010] dark:text-gray-300 mb-4">
        Your name and email address will not be published.
      </p>
      <form>
        <textarea
          className="w-full p-3 border border-[#101010] dark:border-gray-300 bg-light_bg_secondary dark:bg-dark_bg_secondary rounded-md mb-4"
          rows="5"
          placeholder="Type here..."
        ></textarea>
        {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
          <input
            type="text"
            placeholder="Name*"
            className="w-full p-3 border border-gray-300 rounded-md"
          />
          <input
            type="email"
            placeholder="Email*"
            className="w-full p-3 border border-gray-300 rounded-md"
          />
        </div> */}
        <button
          type="submit"
          onClick={(e)=>{e.preventDefault(); alert("You can't comment now!\nSoon you are able to comment")}}
          className="bg-sec_col text-white py-2 px-6 border-2 border-sec_col rounded-md hover:bg-transparent hover:border-[#101010] dark:hover:border-white transition-all duration-300 hover:text-[#101010] dark:hover:text-white"
        >
          Post Comment
        </button>
      </form>
      </div>

        </section>
        
        {/* <section className="pb-8">
        <div className='p-6 rounded-md shadow-md max-w-4xl mx-auto border-2 border-[#101010] dark:border-gray-400 bg-light_bg_secondary dark:bg-dark_bg_secondary'>
        <h2 className="text-lg font-semibold text-[#101010] dark:text-gray-300 mb-2">Comments</h2>
      <p className="text-sm text-[#101010] dark:text-gray-300">
      Catch up on the conversation - the comments are listed below.
      </p>
      <div className='flex mt-8 mb-2'>
        <img src="/images/netsflow.webp" alt="" className="rounded-full cursor-pointer h-10"/>
        <div className='flex flex-col ml-4'>
        <h1 className='font-semibold mb-1'>Muhammad Faizan</h1>
        <span className='text-xs mb-4'>mf2028136@gmail.com</span>
        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat deserunt recusandae, quidem provident, doloremque architecto aperiam maxime, a esse repellendus quae qui est laborum officia. Vero quibusdam repudiandae omnis veniam.</p>
        </div>
      </div>
      </div>
        </section> */}

        <Footer/>
        </>
      );    
}

export default BlogContent
