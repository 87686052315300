import React, { lazy, useEffect } from "react";
import { Link } from "react-router-dom";
import { blogsData } from "../data";
const Navbar = lazy(() => import("../components/Navbar"));
const Footer = lazy(() => import("../components/Footer"));

const Blog = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
      <section>
        <Navbar />
        <section className="min-h-[55vh] lg:min-h-[80vh] relative flex flex-col items-center justify-center">
          <div
            className="min-h-[55vh] lg:min-h-[80vh] absolute top-0 w-full left-0 z-20"
            style={{
              backgroundColor: "#181818",
              opacity: "0.85",
            }}
          ></div>

          <div
            className="min-h-[55vh] lg:min-h-[80vh] absolute top-0 w-full left-0 bg-cover bg-center z-10"
            style={{
              backgroundImage: "url('images/blog.webp')",
            }}
          ></div>

          <div className="min-h-[55vh] lg:min-h-[80vh] absolute top-0 left-0 z-30 w-full flex flex-col items-center justify-center text-center px-6 lg:px-0">
            <h1 className="mb-2 text-[2rem] sm:mb-4 sm:text-[3rem] text-white">BLOGS</h1>
            <p className="text-gray-300 text-md lg:w-[70%] lg:text-xl">
            Explore insightful articles on Netsflow! Our blog delves into the latest trends, expert tips, and innovative solutions to keep you informed. Join us as we share knowledge, inspire creativity, and drive success in the digital world.
            </p>
          </div>
        </section>
      </section>

      <section className="bg-light_bg_secondary dark:bg-dark_bg_secondary min-h-[55vh] lg:min-h-[80vh] px-4 py-4 lg:py-0 flex items-center justify-center">
        <div className="flex justify-center flex-wrap">
          {blogsData.map((elem) => {
            return (
              <div
                className="mb-4 md:mr-4 flex items-center justify-center flex-col sm:w-[80%] md:w-[45%] lg:w-[28%] px-2 py-1 sm:p-2 lg:mb-8 lg:m-8 lg:block border-2 border-[#101010] dark:border-gray-400 lg:p-4"
                key={elem.id}
              >
                <Link to={`/blog/${elem.slug}`}>
                <h1 className="text-md font-bold text-sec_col">Netsflow</h1>
                <h1 className="text-xl mt-2 font-bold text-center lg:text-start text-[#101010] dark:text-gray-300">{elem.title}</h1>
                <h1 className="text-sm mt-5 mb-2 font-bold text-center lg:text-start text-[#101010] dark:text-gray-300">{elem.author}</h1>
                <h1 className="text-sm mb-5 font-bold text-[#101010] dark:text-gray-300">{elem.publishedAt}</h1>
                <span className="text-[#101010] dark:text-gray-300 text-center lg:text-start">
                  {elem.desc}
                </span>
                </Link>
              </div>
            );
          })}
        </div>
      </section>

      {/* <section className="min-h-[55vh] lg:min-h-[80vh] relative">
        <div
          className="min-h-[55vh] lg:min-h-[80vh] absolute top-0 left-0 z-10 w-full bg-cover bg-center"
          style={{
            backgroundImage: "url('images/service_approach.webp')",
          }}
        ></div>

        <div
          className="min-h-[55vh] lg:min-h-[80vh] absolute top-0 left-0 z-20 w-full"
          style={{
            backgroundColor: "#181818",
            opacity: "0.89",
          }}
        ></div>

        <div className="min-h-[55vh] lg:min-h-[80vh] absolute top-0 left-0 z-30 w-full flex items-center px-4 py-4 lg:px-12 lg:py-0 lg:justify-between">
          <div className="flex items-center md:block md:flex-1 approachHidden">
            <img
              draggable="false"
              rel="preload"
              className="h-[65vh]"
              src="images/our_collaboration.webp"
              alt="collaboration"
            />
          </div>
          <div className="flex items-center justify-center flex-col md:flex-1 md:block">
            <h1 className="text-lg font-bold mb-2 text-center lg:text-[2rem] lg:mb-4 md:text-left">
              OUR COLLABORATION
            </h1>
            <p className="text-gray-300 mb-2 text-md text-center md:text-left lg:mb-4 lg:text-lg">
              Our team's strength is rooted in the way we work together,
              blending our diverse skills and expertise to deliver exceptional
              results. Each team member brings something unique to the table,
              and through collaboration, we turn ideas into actionable
              strategies.
            </p>
            <p className="text-gray-300 mb-2 text-md text-center md:text-left lg:mb-8 lg:text-lg">
              At Netsflow, communication is at the core of our collaboration. By
              maintaining open and transparent dialogue, we foster dynamic
              problem-solving and adaptability, ensuring we meet challenges
              head-on.
            </p>
            <div className="">
              <Link to="/contact">
                <button className="flex items-center bg-transparent py-4 px-6 border-2 border-white text-sm text-white font-semibold cursor-pointer my-4 hover:bg-sec_col hover:border-sec_col hover:text-white hover:drop-shadow-btn_custom transition duration-300 ">
                  GET STARTED
                  <FaArrowRight className="ml-2" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section> */}

      <Footer />
    </>
  );
};

export default Blog;
